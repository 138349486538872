<template>
  <main-template>
    <v-alert
      v-if="countImagem"
      class="animate__animated animate__headShake"
      style="position: absolute; right: 15px; width: 420px; height: 50px"
      type="error"
      >Você só pode carregar somente uma <strong>IMAGEM</strong>.</v-alert
    >
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text :to="{ name: 'agenda.index' }">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Agenda
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="11">
        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving || isPreviewing">
          <h4>Carregar imagem</h4>
          <div class="dropbox">
            <input
              type="file"
              multiple
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="
                filesChange($event.target.files);
                fileCount = $event.target.files.length;
                handleFileSelect($event);
              "
              accept="image/*"
              class="input-file"
            />
            <p v-if="isInitial" class="text-center">
              Arraste uma imagem aqui para começar<br />
              ou clique para navegar
            </p>

            <!-- Prévia durante o carregamento -->
            <v-row v-if="isPreviewing" class="image-preview-wrapper justify-center mt-3 mb-3">
              <v-col cols="auto" class="image-container text-center">
                <h3 class="success-title text-center mb-2">Prévia da imagem</h3>
                <v-img
                  :src="previewImage"
                  max-height="200"
                  max-width="300"
                  contain
                  class="main-image mx-auto"
                ></v-img>
              </v-col>
            </v-row>
            <v-row v-if="isSaving" class="image-preview-wrapper justify-center mt-3 mb-3">
              <v-col cols="auto" class="image-container text-center">
                <h3 class="success-title text-center mb-2">Imagem carregada com sucesso!</h3>
                <v-img
                  v-if="closed"
                  :src="url_image_agenda"
                  max-height="200"
                  max-width="300"
                  contain
                  class="main-image mx-auto"
                ></v-img>
              </v-col>
              <v-col cols="auto" class="thumbnail-container text-center">
                <v-img
                  :src="base64Img"
                  max-width="120"
                  contain
                  class="thumbnail-image mx-auto"
                ></v-img>
              </v-col>
            </v-row>
          </div>
        </form>
      </v-col>
      <v-col cols="1" v-if="isSaving || isPreviewing">
        <v-btn color="red" x-small @click="() => deleteImagem()">
          <v-icon color="white" small> fa fa-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="8">
            <ValidationProvider name="turma" rules="required" v-slot="{ errors }">
              <v-select
                :items="gestoes"
                label="Selecione uma turma"
                v-model="gestao"
                :item-text="(item) => item.turma.descricao"
                :item-value="(item) => item.turma_id"
                return-object
                outlined
                :error-messages="errors"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataFormatada"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.data"
                @input="(menu = false), (dataFormatada = formatar(form.data))"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="título" rules="required" v-slot="{ errors }">
              <v-text-field
                counter="35"
                hint="Você só pode digitar até 25 caracteres"
                label="Título"
                v-model="form.titulo"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="conteúdo" rules="required" v-slot="{ errors }">
              <v-textarea
                outlined
                name="input-7-1"
                label="Atividade de casa/sala"
                v-model="form.conteudo"
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { dateFormatParam } from "../../plugins/filters";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
const STATUS_PREVIEW = 4; // Novo status para prévia

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      franquiaAtual: false,
      countImagem: false,
      autoUpdate: true,
      isUpdating: false,
      menu: false,
      gestoes: [],
      base64Img: "",
      name: "Midnight Crew",
      form: {
        titulo: "",
        conteudo: "",
        turma_id: "",
        instrutor_id: "",
        data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      submittingForm: false,
      items: [],
      title: "The summer breeze",
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      gestao: null,
      dateFormatted: "",
      agendaUpload: null,
      url_image_agenda: "",
      previewImage: null,
      closed: true,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isPreviewing() {
      // Novo computed para prévia
      return this.currentStatus === STATUS_PREVIEW;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    formatar(date) {
      return dateFormatParam(date, "d/m/y");
    },

    async submitForm() {
      try {
        this.form.turma_id = this.gestao ? this.gestao.turma_id : null;
        this.form.instrutor_id = this.gestao ? this.gestao.instrutor_id : null;
        if (this.editing) {
          const response = await this.$services.agendasService.update(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Agenda editada com sucesso!");
          this.$router.push({ name: "agenda.index" });
        } else {
          const response = await this.$services.agendasService.store(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Agenda criada com sucesso!");
          this.$router.push({ name: "agenda.index" });
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.previewImage = null;
    },
    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;
      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }

      const file = fileList[0];
      this.agendaUpload = file;

      // Cria a prévia da imagem
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        this.currentStatus = STATUS_PREVIEW;
      };
      reader.readAsDataURL(file);
    },
    async loadData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const response = await this.$services.agendasService.turmas();
        this.gestoes = response.gestoesDeAulas;
        if (this.editing) {
          const response = await this.$services.agendasService.find(
            parseInt(this.$route.params.agenda_id, 10)
          );
          this.form = response;
          if (this.form.imagem) {
            this.currentStatus = STATUS_SAVING; // Alterado para STATUS_PREVIEW
            this.previewImage = this.form.imagem; // Assumindo que form.imagem contém a URL
          }
          this.form.selected = response.tipo;
          this.gestao = {
            id: response.id || null,
            turma_id: response.turma_id || null,
            instrutor_id: response.instrutor_id || null,
            turma: {
              descricao: response.turma.descricao || "",
            },
          };
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async uploadImage(id) {
      this.$loaderService.open("Salvando imagem...");
      try {
        this.closed = false;
        this.currentStatus = STATUS_SAVING; // Muda para estado de salvamento
        await this.$services.imageService.uploadImage(
          "notifiq/agendas/upload-image",
          this.agendaUpload,
          id
        );
        this.currentStatus = STATUS_SUCCESS; // Muda para estado de sucesso após upload
      } catch (error) {
        this.currentStatus = STATUS_FAILED; // Muda para estado de falha
        this.$handleError(error);
      }
    },
    deleteImagem() {
      this.currentStatus = STATUS_INITIAL;
      this.agendaUpload = null;
      this.base64Img = "";
      this.previewImage = null;

      if (this.editing) {
        this.form.imagem = null;
      }

      const inputFile = document.querySelector(".input-file");
      if (inputFile) inputFile.value = "";
    },

    async verAgenda() {
      this.dialog = !this.dialog;

      try {
        const imageUrl = await this.$services.imageService.downloadImage(
          `notifiq/agendas/download-image/${this.$route.params.agenda_id}`
        );

        this.url_image_agenda = imageUrl || "caminho/para/imagem/padrão.jpg";
      } catch (error) {
        this.url_image_agenda = "caminho/para/imagem/padrão.jpg";
      }
    },
  },

  mounted() {
    this.reset();
    this.loadData();
    this.verAgenda();
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
