<template>
  <main-template>
    <v-row>
      <v-col>
        <h2>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'circuito-notas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Configurações do Circuito
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="editing">
      <v-col class="pt-0 pb-0" cols="12">
        <h3 class="mt-3 mb-3">{{ circuitoDados.descricao }}</h3>
        <e-expansion-etapas-list :circuitoId="parseInt($route.params.circuito_id)" />
      </v-col>
    </v-row>
  </main-template>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from "vuex";
import EExpansionEtapasList from "../../components/CircuitoNotas/EExpansionEtapasList.vue";

export default {
  components: { EExpansionEtapasList },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    formCircuito() {
      if (!this.circuitosList || !this.editing) return {};
      const { circuito_id } = this.$route.params;
      const circuito = this.circuitosList
        .filter((cir) => cir.id === parseInt(circuito_id, 10))
        .shift();
      return { ...circuito };
    },
  },
  mounted() {
    this.loadCircuitos();
    this.verificarPermissao();
  },
  data() {
    return {
      circuitos: [],
      circuitosList: [],
      circuitoId: null,
      etapasByCircuito: [],
      submittingForm: false,
      circuitoDados: [],
    };
  },
  methods: {
    async loadCircuitos() {
      try {
        const data = await this.$services.circuitoNotasService.syncAll();
        this.circuitosList = data;
        this.getCircuito();
      } catch (error) {
        this.$handleError(error);
      }
    },
    setCircuito(circuito) {
      this.circuitoId = circuito.id;
    },
    getCircuito() {
      this.circuitoDados = this.circuitosList
        .filter((d) => d.id === parseInt(this.$route.params.circuito_id, 10))
        .shift();
    },
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.formCircuito[campo] = valor;
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.circuitoNotasService.atualizarCircuito(this.formCircuito);
        } else {
          await this.$services.circuitoNotasService.criarCircuito(this.formCircuito);
        }
        this.$toast.success("Circuito Atualizada com sucesso");
        this.$router.push({ name: "circuito-notas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async verificarPermissao() {
      if (this.user.roleName === "admin") {
        this.$toast.danger("Você não tem permissão para acessar essa página");
        this.$router.push({ name: "home" });
      }
    },

    // async loadDataCircuito() {
    //   try {
    //     if (this.circuitos.length === 0) {
    //       this.$loaderService.open("Carregando dados");
    //       await Promise.all([this.loadCircuitos()]);
    //       this.$loaderService.close();
    //     }
    //     this.$forceUpdate();
    //   } catch (error) {
    //     this.$handleError(error);
    //   }
    // },
  },
};
</script>

<style></style>
